import React, { useEffect, useState} from "react";
import Page from "../../layouts/page";
import Api from "../../../Api";
import ClassFilter from "./classfilter";
import helperFunctions from "../../../helperFunctions";
import Loading from "../../common/loading/loading";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";

const ClassList = () => {

    ReactGA.initialize('G-YMQ1Q3Q54L');
    ReactGA.send({ hitType: "pageview", page: "/classes", title: "Classes" });

    const [courseListData, setCourseListData] = useState({
        isLoading: true,
        courses: null
    });
    const [filterData, setFilterData] = useState({
        isLoading: true,
        terms:[],
        courses:[],
        venues:[],
        days:[],
        statuses:['active'],
        itemsperpage: 100000,
        currentpage: 0,
        sortorder: ""
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        cartupdated:false,
        date: undefined
    });
    const [filterMenuToggle, setFilterMenuToggle] = useState({
        show: false,
    });

    const [cartOutOfDate, setCartOutOfDate] = useState(false);

    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);

    useEffect(() => {
        if(cartItems.cartupdated !== undefined && cartItems.cartupdated) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.cartupdated]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    useEffect(() => {
        loadCart();
    }, []);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }
    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));

        if(!isCartExpired(cart)) {
            if (cart != null && !cart.clear) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: cart.date
                });
            } else {
                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: undefined
                });
            }
        }
        else {
            let now = new Date().toString();
            cart = {};
            cart.items = [];
            cart.studentdetails= [];
            cart.contactdetails= []
            cart.isloading= false;
            cart.clear= false;
            cart.cartupdated= false;
            cart.date= now;


            setCartItems({
                ...cartItems,
                items: [],
                studentdetails: [],
                contactdetails: [],
                isloading: false,
                clear: false,
                cartupdated: false,
                date: now
            });
        }


        return cart;
    }
    function loadPage(cart) {
        OnSearch('','');
    }
    function OnSearch(key,value) {
        setCourseListData({...courseListData,
            isLoading: true,
        });
        let termsToFilterBy = filterData.terms;
        let venuesToFilterBy = filterData.venues;
        let coursesToFilterBy = filterData.courses;
        let daysToFilterBy = filterData.days;

        if(key==="term") {
            if(filterData.terms.filter(x => x === value).length > 0) {
                //remove
                setFilterData({...filterData, terms: [...filterData.terms.filter(x => x !== value)]});
                termsToFilterBy = [...termsToFilterBy.filter(x => x !== value)];
            }
            else {
                // add
                setFilterData({...filterData, terms: [...filterData.terms.filter(x => x !== value), value]});
                termsToFilterBy = [...termsToFilterBy, value];
            }
        }
        else if(key==="venue"){
            if(filterData.venues.filter(x => x === value).length > 0){
                setFilterData({...filterData, venues: [...filterData.venues.filter(x => x !== value)]});
                venuesToFilterBy = [...venuesToFilterBy.filter(x => x !== value)];
            }
            else{
                setFilterData({...filterData, venues: [...filterData.venues.filter(x => x !== value), value]});
                venuesToFilterBy = [...venuesToFilterBy, value];
            }
        }
        else if(key==="course"){
            if(filterData.courses.filter(x => x === value).length > 0){
                setFilterData({...filterData, courses: [...filterData.courses.filter(x => x !== value)]});
                coursesToFilterBy = [...coursesToFilterBy.filter(x => x !== value)];
            }
            else{
                setFilterData({...filterData, courses: [...filterData.courses.filter(x => x !== value), value]});
                coursesToFilterBy = [...coursesToFilterBy, value];
            }
        }
        else if(key==="day"){
            if(filterData.days.filter(x => x === value).length > 0){
                setFilterData({...filterData, days: [...filterData.days.filter(x => x !== value)]});
                daysToFilterBy = [...daysToFilterBy.filter(x => x !== value)];
            }
            else{
                setFilterData({...filterData, days: [...filterData.days.filter(x => x !== value), value]});
                daysToFilterBy = [...daysToFilterBy, value];
            }
        }

        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/searchcourses?courses=${coursesToFilterBy.join(',')}&terms=${termsToFilterBy.join(',')}&venues=${venuesToFilterBy.join(',')}&days=${daysToFilterBy.join(',')}&statuses=${filterData.statuses.join(',')}&itemsperpage=${filterData.itemsperpage}&currentpage=${filterData.currentpage}&sortorder=${filterData.sortorder}\``, userToken)
            .then(data => {
                if (data !== undefined) {
                    // Store the data from the API. below is an example
                    setCourseListData({...courseListData,
                        isLoading: false,
                        courses: data
                    });
                } else {
                    console.log('failed to load courses. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load courses');
                console.log(error);
            });
    }

    function closeMobileFilter() {
        setFilterMenuToggle({...filterMenuToggle, show: !filterMenuToggle.show})
    }

    function updateCart(id) {
        let item =  cartItems.items.filter(item =>  item.courselesson.courselessonid === id)[0];
        if(item !== undefined && item !== null) {
            let cartitems  =[];
            if(item.enrolInfullCourse) {
                cartitems  =  cartItems.items.filter(x => x.course.courseId !== item.course.courseId);
            }
            else {
                cartitems  =  cartItems.items.filter(x => x.courseLessonId !== item.courseLessonId);
            }

            setCartItems({
                ...cartItems,
                items: cartitems,
                studentdetails: cartItems.studentdetails,
                contactdetails: cartItems.contactdetails,
                isloading:false,
                clear:false,
                cartupdated: true
            });

        }
    }
    function clearCart() {
        setCartItems({
            items:[],
            studentdetails:[],
            contactdetails:[],
            isloading:true,
            clear:false,
            cartupdated:true
        });
    }

    return (
        <Page pageTitle={"Camps & Classes"} metaTitle={"Camps & Classes - Little House of Science"} metaDescription={"Little House of Science provides after school science activities for children of all ages. Click to view our holiday and after school timetable here."}  cartItems={cartItems.items} updateCart={updateCart} enableShowCart={true}  clearCart={clearCart}>

            <div className="class-list-component">
                <div className="inner">
                    <div className="mobile-filter" onClick={() => {
                        setFilterMenuToggle({...filterMenuToggle, show: !filterMenuToggle.show})
                    }}>
                        <div className={"filter-icon"}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className={"filter-name"}>Filter</div>
                    </div>
                </div>
                <div className="inner">

                    <div className={(filterMenuToggle.show === false)? "class-filter" : "class-filter open"}>
                        <ClassFilter OnSearch={OnSearch} closeMobilePopup={closeMobileFilter} />
                    </div>

                    <div className="class-list">
                        {(!courseListData.isLoading)? (
                            <div className="class-container">
                                {(courseListData.courses !== null &&  courseListData.courses.items.length > 0) ?
                                    (courseListData.courses.items.filter(x => x.isEnrolmentAllowed).map(course => (

                                        (course.status.value === 10) ?
                                            <div key={course.courseId} className="class-item">
                                                <div>
                                                {(course.imageName.length > 0) ?
                                                    <img className={"c-image"} src={course.imageUrl + '/' + course.imageName + '?size=thumb'} width="268px"
                                                         height="160px" alt={course.fullName}/>
                                                    :
                                                    <img className={"c-image"} src={course.imageUrl + '/default.jpg'} width="268px"
                                                         height="160px" alt={course.fullName}/>
                                                }
                                                    <div className={"c-name"}>{course.name}</div>
                                                    <div className={"c-date"}>{helperFunctions.formatDateForDisplay(
                                                        new Date(course.startDate.substring(0,course.startDate.indexOf('T'))),{
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                    })} to {helperFunctions.formatDateForDisplay(
                                                        new Date(course.endDate.substring(0,course.endDate.indexOf('T'))),{
                                                            year: "numeric",
                                                            month: "short",
                                                            day: "numeric",
                                                        })}</div>
                                                </div>
                                                <div className="c-actions">
                                                    {(course.url !== undefined && course.url.length > 0) ?
                                                            <Link to={'/classes/'+course.url} className="btn orange">view options</Link>
                                                        :
                                                            <Link to={'/classes/'+course.courseId} className="btn orange">view options</Link>
                                                    }
                                                </div>
                                            </div>
                                            : ""
                                    )))
                                    :
                                    <div>No courses found</div>
                                }
                            </div>
                        ) : (
                            <Loading/>
                        )}
                    </div>

                </div>

            </div>
        </Page>
    )
}
export default ClassList;
