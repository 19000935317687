import React, {useEffect, useState} from "react";
import Api from "../../Api";

const Footer = (props) => {

    const wpApiBaseUrl = 'https://demowork.co.za/lhos/wp-json/enrolments/v1';

    const [socials, setSocials] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [btnContact, setBtnContact] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [footerColumn1, setFooterColumn1] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [footerColumn2, setFooterColumn2] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [footerColumn3, setFooterColumn3] = useState({
        isLoading: true,
        unsafeHtml:[]
    });
    const [footerColumn4, setFooterColumn4] = useState({
        isLoading: true,
        unsafeHtml:[]
    });

    useEffect(() => {
        loadSocials();
        loadBtnContact();
        loadFooterColumns(1);
        loadFooterColumns(2);
        loadFooterColumns(3);
        loadFooterColumns(4);
    },[]);

    function loadSocials() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(`${wpApiBaseUrl}/footer/socials`)
            .then(data => {
                if (data !== undefined) {
                    setSocials({...socials,
                        isLoading: false,
                        unsafeHtml: data
                    });
                } else {
                    console.log('failed to load footer socials. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to footer socials');
                console.log(error);
            });
    }
    function loadBtnContact() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(`${wpApiBaseUrl}/footer/btn-contact`)
            .then(data => {
                if (data !== undefined) {
                    setBtnContact({...btnContact,
                        isLoading: false,
                        unsafeHtml: data
                    });
                } else {
                    console.log('failed to load contact button. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to contact button');
                console.log(error);
            });
    }
    function loadFooterColumns(column) {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(`${wpApiBaseUrl}/footer/column/` + column)
            .then(data => {
                if (data !== undefined) {
                    if (column === 1) {
                        setFooterColumn1({
                            ...socials,
                            isLoading: false,
                            unsafeHtml: data
                        });
                    } else if(column === 2) {
                        setFooterColumn2({
                            ...socials,
                            isLoading: false,
                            unsafeHtml: data
                        });
                    } else if(column === 3) {
                        setFooterColumn3({
                            ...socials,
                            isLoading: false,
                            unsafeHtml: data
                        });
                    } else if(column === 4) {
                        setFooterColumn4({
                            ...socials,
                            isLoading: false,
                            unsafeHtml: data
                        });
                    }
                } else {
                    console.log('failed to load footer column. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to footer column');
                console.log(error);
            });
    }

    return (
        <div className="template-footer">
            <div className="inner-wrap">
                <div className="row-1">
                  <div className="column-1">
                      <div className="heading-connect">Connect with us</div>
                          {(!socials.isLoading)? (
                              <div className="social-links" dangerouslySetInnerHTML={{__html: socials.unsafeHtml}}></div>
                          ) : null}
                  </div>
                    {(!btnContact.isLoading)? (
                        <div className="column-2" dangerouslySetInnerHTML={{__html: btnContact.unsafeHtml}}></div>
                    ) : null}
                </div>
                <div className="row-2">
                    {(!footerColumn1.isLoading)? (
                        <div className="column-1" dangerouslySetInnerHTML={{__html: footerColumn1.unsafeHtml}}></div>
                    ) : null}
                    <div className="column-2">

                        {(!footerColumn2.isLoading)? (
                            <div className="menu" dangerouslySetInnerHTML={{__html: footerColumn2.unsafeHtml}}></div>
                        ) : null}

                        {(!footerColumn3.isLoading)? (
                            <div className="menu" dangerouslySetInnerHTML={{__html: footerColumn3.unsafeHtml}}></div>
                        ) : null}

                        {(!footerColumn4.isLoading)? (
                            <div className="menu" dangerouslySetInnerHTML={{__html: footerColumn4.unsafeHtml}}></div>
                        ) : null}

                    </div>
                </div>
                <div className="row-3">
                    <div className="copyright">
                        &copy;Little House of Science | All Rights reserved
                    </div>
                    <div className="site-credit">
                        web developers london - lilo.co.uk
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;
