import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../../Api";
import Page from "../../layouts/page";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import Progress from "../Progress/progress";
import ReactGA from "react-ga4";

const Payment = (props) => {
    ReactGA.initialize('G-YMQ1Q3Q54L');
    ReactGA.send({ hitType: "pageview", page: "/checkout/payment", title: "Checkout - Payment" });

    // URL parameter
    let { orderid , paymentgatewayid } = useParams();
    const navigate = useNavigate();
    const [stripeData, setStripeData] = useState({
        isLoading: true,
        options:{},
        stripePromise:{},
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true
    });


    useEffect(() => {
        loadCart();
    }, [])
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    function loadPage(cart) {
        if(cart == null || cart == undefined || cart.items == null || cart.items.length === 0) {
            navigate('/classes');
        }
        else if(!IsLoggedIn()) {
            navigate('/?returnurl=/checkout/contacts');
        }
        else {
            let data = {
                orderid: orderid,
                paymentgatewayid: paymentgatewayid,
                userid: sessionStorage.getItem('userid'),
                token:     sessionStorage.getItem('twtoken')
            }
            Api.postAuthorizedData(process.env.REACT_APP_API_URL + `frontend/createpayment`,data,sessionStorage.getItem('twtoken') )
                .then(data => {
                    if (data !== undefined && (data.status !== 400 || data.Status !== 400)) {
                        setStripeData({...stripeData, isLoading: false, stripePromise: loadStripe(data.publicKey),options: {clientSecret: data.clientSecret}});

                    } else {
                        var c ='';
                    }
                })
                .catch(error => {
                    var c ='';
                });
        }
    }
    function IsLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    return (
        <Page pageTitle={"Checkout/Payment"} metaTitle={"Payment | Checkout | Little House of Science"} metaDescription={"Little House of Science checkout"}   cartItems={cartItems.items} enableShowCart={false}>
            <div className="payment-form-component">

                <div className="inner">
                    <div className="payment-container">
                        <Progress step={4} />
                        {(!stripeData.isLoading) ? (
                                    <EmbeddedCheckoutProvider stripe={stripeData.stripePromise} options={stripeData.options}>
                                        <EmbeddedCheckout />
                                    </EmbeddedCheckoutProvider>
                            ) :""}

                    </div>
                </div>
            </div>
        </Page>
    )
}
export default Payment;
