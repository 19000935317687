import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Cart from "./Cart";
import Api from "../../Api";
import Account from "./Account";
const Header = (props) => {

    const wpBaseURL = 'https://demowork.co.za/lhos/';
    const wpApiBaseUrl = 'https://demowork.co.za/lhos/wp-json/enrolments/v1';

    const [menuItems, setMenuItems] = useState({
        isLoading: true,
        items: [],
        unsafeHtml: undefined
    });

    const [mobileMenu, setMobileMenu] = useState({
        isLoading: true,
        unsafeHtml: [],
        showMobileMenu: false,
    });
    const [showAccountMenu, setshowAccountMenu] = useState(false);

    useEffect(() => {
        loadMenuItems();
    },[]);

    // Load the menu items from the WordPress site through the rest API.
    function loadMenuItems() {
        // process.env.REACT_WORDPRESS_API_URL
        Api.getData(`${wpApiBaseUrl}/menu/desktop`)
            .then(data => {
                if (data !== undefined) {
                    setMenuItems({...menuItems,
                        isLoading: false,
                        unsafeHtml: data
                    });
                } else {
                    console.log('failed to load desktop navigation. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to desktop navigation');
                console.log(error);
            });
    }
    function isLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }
    const OpenCartDialog = () => {
        let dialog = document.getElementById('viewCartModal')
        dialog.showModal();
    }
    const CloseCartDialog = () => {
        let dialog = document.getElementById('viewCartModal')
        dialog.close();
    }
    const OpenAccountDialog = () => {
        setshowAccountMenu(true);
    }
    function CloseAccountDialog(){
        setshowAccountMenu(false);
    }

    function showMobileMenu() {
        setMobileMenu({...mobileMenu,
            showMobileMenu: !mobileMenu.showMobileMenu
        })
    }

    return (
        <>
        <div className="template-header">
            <div className="inner-wrap">
                <div className="top-info-bar">
                    <div>
                        <a href="tel:08000921062">
                            <span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-fas-phone-alt" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg" width={20} height={16}><path
                                d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>						</span>
                            <span className="elementor-icon-list-text">0800 092 1062</span>
                        </a>
                        <a href="mailto:info@littlehouseofscience.com">
							<span className="elementor-icon-list-icon">
							<svg aria-hidden="true" className="e-font-icon-svg e-far-envelope" viewBox="0 0 512 512"
                                 xmlns="http://www.w3.org/2000/svg" width={20} height={16}><path
                                d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>						</span>
                            <span className="elementor-icon-list-text">info@littlehouseofscience.com</span>
                        </a>
                    </div>
                </div>
                <div className="top-bar">
                    <div className="site-logo">
                        <a href={wpBaseURL}>
                            <img src="/assets/images/LHOS-logo-v2-170px.png" alt="Logo - Little House of Science" height={154} width={170}/>
                        </a>
                    </div>
                    <div className={"mobile-alignment"}>
                        <div className="main-nav">

                            {(!menuItems.isLoading) ? (
                                <div className={(mobileMenu.showMobileMenu) ? 'show-mobile' : null}>
                                    {(mobileMenu.showMobileMenu) ? (
                                        <div className={"mobile-top-bar"}>
                                            <a href={wpBaseURL}>
                                                <img src="/assets/images/LHOS-logo-v2-170px.png"
                                                     alt="Logo - Little House of Science" height={154} width={170}/>
                                            </a>
                                            <div className="mobile-menu-toggle" onClick={showMobileMenu}>
                                                <span></span><span></span><span></span>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div dangerouslySetInnerHTML={{__html: menuItems.unsafeHtml}}></div>
                                </div>
                            ) : null}
                            <div><Link to={"/classes"} className="btn orange">Book classes</Link></div>

                        </div>

                        <div className="user-actions">
                            <div className="account-icon-section">
                                {(isLoggedIn()) ?<div onClick={OpenAccountDialog}> <img className="action" src="/assets/images/user.svg" alt="Account"/></div>     :
                                <Link to='/accounts'> <img className="action" src="/assets/images/user.svg" alt="Account"/></Link>
                            }
                            {/*{(showAccountMenu) ? <Account  cartItems={props.cartItems} updateCart={props.updateCart} CloseAccountDialog={CloseAccountDialog} />:''}*/}
                            <Account  cartItems={props.cartItems} updateCart={props.updateCart} CloseAccountDialog={CloseAccountDialog} />
                            </div>
                            {(props.enableShowCart) ?
                                (<div onClick={OpenCartDialog}><img className="action" src="/assets/images/shopping-bag.svg" alt="Cart"/></div>)
                                :
                                (<div><img className="action" src="/assets/images/shopping-bag.svg" alt="Cart"/></div>)
                            }
                            {(props.enableShowCart && props.cartItems !== undefined && props.cartItems !== null && props.cartItems.length > 0) ? (<div className="cartitems alert" onClick={OpenCartDialog}>{props.cartItems.length }</div>) :'' }
                        </div>

                        <div className="mobile-nav">
                            <div className="mobile-menu-toggle" onClick={showMobileMenu}>
                                <span></span><span></span><span></span>
                            </div>
                        </div>

                    </div>
                    {(!mobileMenu.isLoading) ? (
                        <>
                            <div dangerouslySetInnerHTML={{__html: mobileMenu.unsafeHtml}}></div>
                        </>
                    ) : null}
                </div>
                <div className="bottom-bar">
                    {/*<img src="/assets/images/header-background.png" alt="title background"/>*/}
                    <div className="bottom-bar-inner">
                        {(props.pageTitle !== undefined && props.pageTitle !== '')? (
                            <h1 className="page-title">{props.pageTitle}</h1>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
            {(props.enableShowCart) ?
                <dialog id="viewCartModal" className="modal">
                    <div id="closeModal" className="modal-close-btn" onClick={CloseCartDialog}>
                        <img src="/assets/images/cross.svg" alt="Close" loading={"lazy"} width={20} height={20}/>
                    </div>
                    <Cart cartItems={props.cartItems} updateCart={props.updateCart} clearCart={props.clearCart} />
                </dialog>
                :'' }
        </>
    )
}
export default Header;
