import React, { useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../../layouts/page";
import Progress from "../Progress/progress";
import Required from "../../common/Required";
import ReactGA from "react-ga4";

const ContactDetails = () => {
    ReactGA.initialize('G-YMQ1Q3Q54L');
    ReactGA.send({ hitType: "pageview", page: "/checkout/contacts", title: "Checkout - Contact Details" });

    const [showEarlyDropOff, setShowEarlyDropOff] = useState({
        list:[]
    });
    const [cartData, setCartData] = useState({
        isLoading: true,
        cart:[],
        students:[]
    });
    const navigate = useNavigate();
    const [formValidation, setFormValidation] = useState({
        isLoading: false,
        showApiError: false,
        errors:[]
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        next:false
    });

    useEffect(() => {
        loadCart();
    }, [])
    useEffect(() => {
        if(cartItems.next && cartItems.contactdetails !== undefined && cartItems.contactdetails.length > 0) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
            navigate('/checkout/confirmation');
        }
    }, [cartItems.contactdetails])
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined)  {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    function loadPage(cart) {
        if(cart === null || cart === undefined || cart.items === null || cart.items.length === 0 || cart.studentdetails === null || cart.studentdetails.length ===0) {
            navigate('/classes');
        }
        else if(!IsLoggedIn()) {
            navigate('/?returnurl=/checkout/contacts');
        }
   /*     else if(
            cart !== undefined &&
            cart.items !== undefined &&
            cart.studentdetails !== undefined &&
            cart.studentdetails.length > 0  &&
            cart.contactdetails !== undefined &&
            cart.contactdetails.length > 0)
        {
            navigate('/checkout/confirmation');
        }*/
        else {

            let students = [];
            cart.studentdetails.map(x => {
                    if (students.filter(y => y.id === x.id).length === 0) {
                        students.push({id: x.id, name: x.firstname + ' ' + x.lastname});
                    }
                }
            );

            setCartData({
                ...cartData,
                cart: cart,
                isLoading: false,
                numberOfStudents: students.length,
                students: students,
                studentdetails: cart.studentdetails,
                lessons: cart.items,
            });
        }
    }
    function saveCart(data) {
        if(data !== undefined && data !== null ) {
            setCartItems({
                ...cartItems,
                items: cartItems.items,
                studentdetails: cartItems.studentdetails,
                contactdetails: data,
                next: true,
                date: cartItems.date
            });
        }
    }
    function IsLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }

    function setArrayValues(array,key,value) {
        if(array.filter(x => x.key === key).length > 0)
            array.filter(x => x.key === key)[0].value = value
        else
            array.push({key: key,value: value});
    }
    function getArrayValue(array,key) {
        if(array.filter(x => x.key === key).length > 0)
            return array.filter(x => x.key === key)[0].value;
        return  null;
    }

    const OnNext = (e) => {
        e.preventDefault();
        let form = e.target;
        let formData = new FormData(form);
        let contactData = [];
        let errors = [];

        cartData.students.map(student => {
                //set error values to false
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'FirstNameValidationError', false);
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'LastNameValidationError', false);
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'EmailValidationError', false);
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'MobileValidationError', false);

                let hasError = false;
                let emergencycontactfirstname = formData.get('emergencycontact' + student.id + 'firstname');
                let emergencycontactlastname = formData.get('emergencycontact' + student.id + 'lastname');
                let emergencycontactemailaddress = formData.get('emergencycontact' + student.id + 'email');
                let emergencycontactmobile = formData.get('emergencycontact' + student.id + 'mobile');

                setArrayValues(errors, 'showEmergencyContact' + student.id + 'FirstNameValidationError', (emergencycontactfirstname.length === 0));
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'LastNameValidationError', (emergencycontactlastname.length === 0));
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'EmailValidationError', (emergencycontactemailaddress.length === 0));
                setArrayValues(errors, 'showEmergencyContact' + student.id + 'MobileValidationError', (emergencycontactmobile.length === 0));

                hasError = (errors.filter(x => x.value === true).length > 0);

                cartData.studentdetails.filter((x => x.id === student.id)).map(item => {
                    cartData.lessons.filter(x => x.courseLessonId === item.courselessonid).map(course => {

                        setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'FirstNameValidationError', false);
                        setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'LastNameValidationError', false);
                        setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'EmailAddressValidationError', false);
                        setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'MobileValidationError', false);

                        setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'FirstNameValidationError', false);
                        setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'LastNameValidationError', false);
                        setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'EmailAddressValidationError', false);
                        setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'MobileValidationError', false);

                        let earlydropofffirstname = formData.get('earlydropoff' + student.id + course.courseLessonId + 'firstname');
                        let earlydropofflastname = formData.get('earlydropoff' + student.id + course.courseLessonId + 'lastname');
                        let earlydropoffemailaddress = formData.get('earlydropoff' + student.id + course.courseLessonId + 'email');
                        let earlydropoffmobile = formData.get('earlydropoff' + student.id + course.courseLessonId + 'mobile');

                        //non-mandatory only check all fields if someone tries to data
                        if (earlydropofffirstname !== null && earlydropofffirstname !== undefined && earlydropofffirstname.length > 0) {
                            setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'FirstNameValidationError', (earlydropofflastname.length === 0));
                            setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'LastNameValidationError', (earlydropofflastname.length === 0));
                            setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'EmailAddressValidationError', (earlydropoffemailaddress.length === 0));
                            setArrayValues(errors, 'showEarlyDropOff' + student.id + course.courseLessonId + 'MobileValidationError', (earlydropoffmobile.length === 0));

                            hasError = (errors.filter(x => x.value === true).length > 0);
                        }


                        let latepickupfirstname = formData.get('latepickup' + student.id + course.courseLessonId + 'firstname');
                        let latepickuplastname = formData.get('latepickup' + student.id + course.courseLessonId + 'lastname');
                        let latepickupemailaddress = formData.get('latepickup' + student.id + course.courseLessonId + 'email');
                        let latepickupmobile = formData.get('latepickup' + student.id + course.courseLessonId + 'mobile');

                        if ((latepickupfirstname !== null && latepickupfirstname.length > 0) || (latepickuplastname !== null && latepickuplastname.length > 0)) {
                            setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'FirstNameValidationError', (latepickuplastname.length === 0));
                            setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'LastNameValidationError', (latepickuplastname.length === 0));
                            setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'EmailAddressValidationError', (latepickupemailaddress.length === 0));
                            setArrayValues(errors, 'showLatePickUp' + student.id + course.courseLessonId + 'MobileValidationError', (latepickupmobile.length === 0));
                            hasError = (errors.filter(x => x.value === true).length > 0);
                        }

                        if (!hasError) {
                            let studentdata = cartData.studentdetails.filter(x => x.id == student.id)[0];
                            let data = {
                                studentId: student.id,
                                course: course.course.courseId,
                                courseLessonId: course.courseLessonId,
                                enrolInfullCourse: course.enrolInfullCourse,
                                emergencycontact: {
                                    firstname: emergencycontactfirstname,
                                    lastname: emergencycontactlastname,
                                    mobile: emergencycontactmobile,
                                    emailaddress: emergencycontactemailaddress
                                },
                                latepickup: {
                                    firstname: latepickupfirstname,
                                    lastname: latepickuplastname,
                                    mobile: latepickupmobile,
                                    emailaddress: latepickupemailaddress
                                },
                                earlydroppff: {
                                    firstname: earlydropofffirstname,
                                    lastname: earlydropofflastname,
                                    mobile: earlydropoffmobile,
                                    emailaddress: earlydropoffemailaddress
                                },
                                student: studentdata
                            };
                            contactData.push(data);
                        }
                    })
                })
            }
        )

        let hasErrors = (errors.filter(x => x.value === true).length > 0);
        if (!hasErrors) {
            saveCart(contactData);
        } else {
            setFormValidation({
                ...formValidation,
                errors: errors,
            });
        }
    }

    const OnCancel = (e) => {
        navigate('/checkout/details');
    }

    const DisplayEmergencyContacts = (student) => {
        let contactfirstname='';
        let contactlastname='';
        let contactmobile='';
        let contactemailaddress='';

        if(cartData!==undefined && cartData.cart !== undefined && cartData.cart.contactdetails !== undefined) {
            let savedStudentData =  cartData.cart.contactdetails .filter(x => x.studentId == student.id)[0];
            if(savedStudentData != undefined) {
                contactfirstname = savedStudentData.emergencycontact.firstname;
                contactlastname = savedStudentData.emergencycontact.lastname;
                contactmobile = savedStudentData.emergencycontact.mobile;
                contactemailaddress = savedStudentData.emergencycontact.emailaddress;
            }
        }

        return (<div className="student-emergencycontanct-container">
                <div className="sub-heading">Emergency Contact</div>
                    <div className="field-row">
                        <div className="field-data">
                            <label htmlFor={'emergencycontact'+student.id+'firstname'}>First Name</label><Required/>
                            <input type="text" name={'emergencycontact'+student.id+'firstname'} id={'emergencycontact'+student.id+'firstname'}  defaultValue={contactfirstname} />
                        </div>
                        <div className="field-data">
                            <label htmlFor={'emergencycontact'+student.id+'lastname'}>Last Name</label><Required/>
                            <input type="text" name={'emergencycontact'+student.id+'lastname'} id={'emergencycontact'+student.id+'lastname'}  defaultValue={contactlastname} />
                        </div>
                        <div className="field-data">
                            <label htmlFor={'emergencycontactemail'}>Email Address</label><Required/>
                            <input type="text" name={'emergencycontact'+student.id+'email'} id={'emergencycontact'+student.id+'email'}  defaultValue={contactemailaddress}  />
                        </div>
                        <div className="field-data">
                            <label htmlFor={'emergencycontact'+student.id+'mobile'}>Mobile</label><Required/>
                            <input type="text" name={'emergencycontact'+student.id+'mobile'} id={'emergencycontact'+student.id+'mobile'}  defaultValue={contactmobile} />
                        </div>
                    </div>

                    {(getArrayValue(formValidation.errors,'showEmergencyContact'+student.id+'FirstNameValidationError')) ? (
                        <div className="validation-error">Please enter your first name</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEmergencyContact'+student.id+'LastNameValidationError'))? (
                        <div className="validation-error">Please enter your last name</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEmergencyContact'+student.id+'EmailValidationError'))? (
                        <div className="validation-error">Please enter your email address</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEmergencyContact'+student.id+'MobileValidationError'))? (
                        <div className="validation-error">Please enter your mobile</div>
                    ) : null }
        </div>)
    }
    const DisplayLatePickup = (student,course) => {
        let firstname='';
        let lastname='';
        let mobile='';
        let emailaddress='';

        if(cartData!==undefined && cartData.cart !== undefined && cartData.cart.contactdetails !== undefined) {
            let savedStudentData =  cartData.cart.contactdetails .filter(x => x.studentId == student.id && x.courseLessonId == course.courseLessonId)[0];
            if(savedStudentData != undefined) {
                firstname = savedStudentData.emergencycontact.firstname;
                lastname = savedStudentData.emergencycontact.lastname;
                mobile = savedStudentData.emergencycontact.mobile;
                emailaddress = savedStudentData.emergencycontact.emailaddress;
            }
        }

        return (<div className="student-latepickup-container">
                    <div className="sub-heading">Late Pickup Contact</div>
                    <div className="field-row">
                        <div className="field">
                            <label htmlFor={'latepickup'+student.id+course.courseLessonId+'firstname'}>First Name</label>
                            <input type="text" name={'latepickup'+student.id+course.courseLessonId+'firstname'} id={'latepickup'+student.id+course.courseLessonId+'firstname'} defaultValue={firstname} />
                        </div>
                        <div className="field">
                            <label htmlFor={'latepickup'+student.id+course.courseLessonId+'lastname'}>Last Name</label>
                            <input type="text" name={'latepickup'+student.id+course.courseLessonId+'lastname'} id={'latepickup'+student.id+course.courseLessonId+'lastname'}  defaultValue={lastname} />
                        </div>
                        <div className="field">
                            <label htmlFor={'latepickup'+student.id+course.courseLessonId+'email'}>Email Address</label>
                            <input type="text" name={'latepickup'+student.id+course.courseLessonId+'email'} id={'latepickup'+student.id+course.courseLessonId+'email'}  defaultValue={emailaddress} />
                        </div>
                        <div className="field">
                            <label htmlFor={'latepickup'+student.id+course.courseLessonId+'mobile'}>Mobile</label>
                            <input type="text" name={'latepickup'+student.id+course.courseLessonId+'mobile'} id={'latepickup'+student.id+course.courseLessonId+'mobile'}  defaultValue={mobile} />
                        </div>

                    </div>

            {(getArrayValue(formValidation.errors,'showLatePickUp'+student.id+course.courseLessonId+'FirstNameValidationError'))? (
                <div className="validation-error">Please enter your first name</div>
            ) : null }
            {(getArrayValue(formValidation.errors,'showLatePickUp'+student.id+course.courseLessonId+'LastNameValidationError'))? (
                <div className="validation-error">Please enter your last name</div>
            ) : null }
            {(getArrayValue(formValidation.errors,'showLatePickUp'+student.id+course.courseLessonId+'EmailAddressValidationError'))? (
                <div className="validation-error">Please enter your email address</div>
            ) : null }
            {(getArrayValue(formValidation.errors,'showLatePickUp'+student.id+course.courseLessonId+'MobileValidationError'))? (
                <div className="validation-error">Please enter your mobile</div>
            ) : null }
        </div>)
    }
    const onChangeShowEarlyDropOff =(e,studentId,courseId) => {

        let option = showEarlyDropOff.list.filter(x => x.studentId === studentId && x.courseId === courseId)[0];
        if(option === undefined || option.length === 0) {
            option = {
                studentId: studentId,
                courseId: courseId
            }
        }

        option.checked = e.target.checked;

        setShowEarlyDropOff({...showEarlyDropOff, list: [...showEarlyDropOff.list.filter(x => x.studentId !== studentId && x.courseId !== courseId), option]});

        let data = cartData;
        data.isLoading = true;

        setCartData(data);

        data = cartData;
        data.isLoading = false;

        setCartData(data);
    }
    const DisplayEarlyDropOff = (student,course) => {
        let firstname='';
        let lastname='';
        let mobile='';
        let emailaddress='';

        if(cartData!==undefined && cartData.cart !== undefined && cartData.cart.contactdetails !== undefined) {
            let savedStudentData =  cartData.cart.contactdetails .filter(x => x.studentId == student.id && x.courseLessonId == course.courseLessonId)[0];
            if(savedStudentData != undefined) {
                firstname = savedStudentData.emergencycontact.firstname;
                lastname = savedStudentData.emergencycontact.lastname;
                mobile = savedStudentData.emergencycontact.mobile;
                emailaddress = savedStudentData.emergencycontact.emailaddress;
            }
        }

        let option = showEarlyDropOff.list.filter(x => x.studentId === student.id && x.courseId === course.courseLessonId)[0];

        return  <>
                <div className="sub-heading">Early Drop Off Contact</div>
                    <div className="student-earlydropoff-container">
                    <div className="field-row">
                        <div className="field">
                            <label htmlFor={'earlydropoff'+student.id+course.courseLessonId+'firstname'}>First Name</label>
                            <input type="text" name={'earlydropoff'+student.id+course.courseLessonId+'firstname'} id={'earlydropoff'+student.id+course.courseLessonId+'firstname'} defaultValue={firstname} />
                        </div>
                        <div className="field">
                            <label htmlFor={'earlydropoff'+student.id+course.courseLessonId+'lastname'}>Last Name</label>
                            <input type="text" name={'earlydropoff'+student.id+course.courseLessonId+'lastname'} id={'earlydropoff'+student.id+course.courseLessonId+'lastname'}  defaultValue={firstname}  />
                        </div>
                        <div className="field">
                            <label htmlFor={'earlydropoff'+student.id+course.courseLessonId+'email'}>Email Address</label>
                            <input type="text" name={'earlydropoff'+student.id+course.courseLessonId+'email'} id={'earlydropoff'+student.id+course.courseLessonId+'email'} defaultValue={firstname}  />
                        </div>
                        <div className="field">
                            <label htmlFor={'earlydropoff'+student.id+course.courseLessonId+'mobile'}>Mobile</label>
                            <input type="text" name={'earlydropoff'+student.id+course.courseLessonId+'mobile'} id={'earlydropoff'+student.id+course.courseLessonId+'mobile'} defaultValue={firstname}  />
                        </div>
                    </div>
                    {(getArrayValue(formValidation.errors,'showEarlyDropOff'+student.id+course.courseLessonId+'FirstNameValidationError'))? (
                        <div className="validation-error">Please enter your first name</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEarlyDropOff'+student.id+course.courseLessonId+'LastNameValidationError'))? (
                        <div className="validation-error">Please enter your last name</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEarlyDropOff'+student.id+course.courseLessonId+'EmailAddressValidationError'))? (
                        <div className="validation-error">Please enter your email address</div>
                    ) : null }

                    {(getArrayValue(formValidation.errors,'showEarlyDropOff'+student.id+course.courseLessonId+'MobileValidationError'))? (
                        <div className="validation-error">Please enter your mobile</div>
                    ) : null }
            </div>
            </>
    }
    const DisplayCourses = (student) => {
        let form =[]
        cartData.studentdetails.filter((x => x.id === student.id)).map( item => {
                cartData.lessons.filter(x => x.courseLessonId === item.courselessonid).map(course => {
                let displayEarlyDropOff =undefined;
                if(item.enrolInFullCourse) {
                    displayEarlyDropOff = (course.course.enableEarlyDropOff) ? DisplayEarlyDropOff(student, course) : '';
                }
                else {
                    displayEarlyDropOff = (course.courselesson.enableEarlyDropOff) ? DisplayEarlyDropOff(student, course) : '';
                }

                let displayLatePickup = undefined;
                if(item.enrolInFullCourse) {
                    displayLatePickup = (course.course.enableLatePickup) ? DisplayLatePickup(student, course) :'';
                }
                else {
                    displayLatePickup = (course.courselesson.enableEarlyDropOff) ? DisplayEarlyDropOff(student, course) : '';
                }

                if(displayLatePickup !== "" || displayEarlyDropOff !== "") {
                form.push(<div className="course-container">
                    {(item.enrolInFullCourse) ?(<div className="course-full-name">{course.course.fullName}</div>):(<div className="course-full-name">{course.courselesson.name}</div>)}
                    {displayEarlyDropOff}
                    {displayLatePickup}
                </div>)
                }
                })
            }
        )
        return form
    }
    const DisplayStudent = (student) => {
        var displayContacts = DisplayEmergencyContacts(student);
        var displayCourses = DisplayCourses(student);
        return <div className="student-container">
                    <div className="course-full-name">{student.name}</div>
                    {displayContacts}
                    {displayCourses}
        </div>
    }

    return (
        <Page pageTitle={"Checkout / Contact Details"} metaTitle={"Contact Details | Checkout | Little House of Science"} metaDescription={"Little House of Science checkout"}  cartItems={cartItems.items} enableShowCart={false}>
            <div className="contactdetails-form-component">

                <div className="inner">
                    <div className="contactdetails-container">
                        <Progress step={2} />

                        <div className="course-name">
                            <h2>Contact Details</h2>
                        </div>

                        <form className="form contact-form" onSubmit={OnNext} >
                                {(!cartData.isLoading) ? (
                                    cartData.students.map( student =>
                                        DisplayStudent(student)
                                    )
                                ) : ""}

                                <div className="action-container">
                                    <button className="class-action btn cancel"  onClick={() => OnCancel()}>Back</button>
                                    <button className="class-action btn orange" type="submit">Next</button>
                                </div>
                            </form>


                    </div>
                </div>
            </div>
        </Page>
    )
}
export default ContactDetails;
